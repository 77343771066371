


























import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import api from "@/api";

@Component({
  name: 'CreateLanguageText'
})
export default class CreateLanguageText extends Vue{
  @Ref() readonly dataForm!: ElForm;

  loading = true;
  show = false;

  form = {
    // source: "",
    key: ""
  }

  defaultData = {
    // source: '',
    key: ''
  }

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      // if (this.form.source) {
        api.language.createLanguageText({ body: this.form }).then(() => {
          this.show = false;
          this.form.key = ''
          this.$emit('fetchData')
        });
      // }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      // if (this.form.source) {
        this.loading = false;
        this.form.key = '';
      // } else {
      //   this.loading = true;
      // }
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    key: [
      {
        required: true,
        message: (this as any).$l.getLanguageText('languageTexts.enterKey') as string,
        trigger: "blur"
      }
    ]
  };

  get title() {
    return (this as any).$l.getLanguageText('basic.create') as string;
  }

  cancel() {
    this.show = false;
  }
};
